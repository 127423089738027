<template>
  <div class="slide-item">
    <nuxt-img
      :src="String(props.item?.imageId)"
      :loading="isFirst ? 'eager' : 'lazy'"
      width="775px"
      height="370px"
      class="slide-item__image"
    />
  </div>
</template>

<script lang="ts" setup>
import { type ISlide } from "~/cms/blocks/SpecialBlock.vue";

interface IProps {
  item: ISlide | null;
  isFirst: boolean;
};
const props = withDefaults(defineProps<IProps>(), {});

</script>

<style scoped lang="scss">
.slide-item {
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  width: 100%;
  border: 1px solid hsla(var(--main-section-bg)/1);
  box-shadow: 0px 8px 16px 0px hsla(var(--black)/0.05);

  &:after {
    content: "";
    float: left;
    width: 100%;
    padding-top: 48%;
  }
}
.slide-item__image {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
}
</style>
