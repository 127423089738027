<template>
  <section class="special-v2-block">
    <div class="container">
      <client-only>
        <template #fallback>
          <SlideItem
            :item="firstSlideItem"
            :is-first="true"
          />
        </template>
        <swiper
          effect="cards"
          :modules="[EffectFade, Pagination, Autoplay]"
          :loop="true"
          :_autoplay="{delay: sliderAutoplayDelay}"
          :pagination="paginationOptions"
          :observer="true"
          :observe-parents="true"
          :space-between="32"
          class="special-v2-block-swiper"
        >
          <swiper-slide
            v-for="(slide, index) in sliderItems"
            :key="`swiper-slide-${ index }`"
          >
            <SlideItem
              :item="slide"
              :is-first="Boolean(index <= 0)"
            />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from "#sitis/internal/models/common";
import type { ComputedRef } from 'vue';
import { EffectFade, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SlideItem from "~/components/cms/special-block/SlideItemV2.vue";

export interface ISlide {
  link: string;
  isGif: boolean;
  imageId: number | null;
}

const props = defineProps<IBlockDefaultProps>();

const sliderItems: ComputedRef<ISlide[]> = computed(() => {
  return [...(props?.values?.items || []), ...(props?.values?.items || []), ...(props?.values?.items || [])]
    .map((t: any) => {
      return {
        link: t?.link,
        isGif: Boolean(t.is_gif),
        imageId: Number.parseFloat(t?.image || '0'),
      };
    });
});
const firstSlideItem: ComputedRef<ISlide | null> = computed(() => {
  return sliderItems.value?.[0] || null
});
const sliderAutoplayDelay: ComputedRef<number | undefined> = computed(() => {
  return props?.values?.interval_seconds * 1000 || undefined
});

const paginationOptions = computed(() => {
  return {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<div class="${className} custom-bullet"></div>`;
    }
  };
});
</script>

<style lang="scss">
.special-v2-block {
  display: flex;
  position: relative;
  //overflow: hidden;
}
.special-v2-block-swiper {
  width: calc(100% + 40px);
  margin: -20px;
  padding: 20px;
  box-sizing: border-box;

  .swiper-slide {
    width: 100%!important;
  }
  .swiper-pagination {
    text-align: left;
    left: 52px; bottom: 30px;
  }
  .swiper-pagination-bullet {
    width: 12px; height: 12px;
    border-radius: 100%;
    border: 1px solid hsla(var(--primary-bg)/1);
    background: transparent;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: hsla(var(--primary-bg)/1);
  }
}

@media (max-width: 1023px) {
  .special-v2-block-swiper {
    width: 100%;
    margin: -20px 0;
    padding: 20px 0;
  }
}
</style>
